/** @jsx jsx */
import { jsx, Grid, Flex, Button } from 'theme-ui';
import React, { useState, useEffect } from 'react' 
import Layout from '../../components/layout'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import { Archive } from '../../components/hero'
import { graphql } from 'gatsby'
import { ImageCard } from '../../components/card'
import { responsive, chunk } from '../../utils/helpers'
import Modal, { Overlay, Close } from '../../components/modal'
import GalleryCarousel from '../../components/gallery'
import { useMediaQuery } from 'react-responsive'
import { navigate } from 'gatsby'
import update from 'immutability-helper'
import MetaData from '../../components/metadata'

const GalleryModal = ({setGallery, gallery, chunked, currentPage, totalPages}) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  if (!gallery) return null

  const currentState = {
    chunked,
    currentPage,
    totalPages
  }

  return (
    <React.Fragment>
      <Overlay onClick={() => navigate('/gallery', {state: currentState})} />
      <Close onClick={() => navigate('/gallery', {state: currentState})} />
      <Modal sx={{width: '100%'}} scrollToTop bleed>
        {isTabletOrMobile &&
          <GalleryCarousel items={gallery.items} aspectRatio={1/1} />
        }
        {isDesktop &&
          <GalleryCarousel items={gallery.items} aspectRatio={16/9} />
        }
      </Modal>
    </React.Fragment>
  )
}


const Gallery = (props) => {

  const locationState = props.location?.state

  const [gallery, setGallery] = useState()
  const [initialLoad, setInitialLoad] = useState(true)
  const [chunked, setChunked] = useState(locationState && locationState.chunked ? locationState.chunked : [])
  const [currentPage, setCurrentPage] = useState(locationState && locationState.currentPage ? locationState.currentPage : 0)
  const [totalPages, setTotalPages] = useState(locationState && locationState.totalPages ? locationState.totalPages : 0)
  const galleries = props.data?.allContentfulGallery?.edges
  const itemsPerPage = 16

  useEffect(() => {
    if (locationState.chunked && initialLoad) return
    const chunks = chunk(galleries, itemsPerPage)
    setTotalPages(chunks.length)
    
    if (chunks.length) {
      setChunked(r => update(r, {
        $push: chunks[currentPage]
      }))
    }
    
  }, [
    currentPage,
    galleries,
  ])

  useEffect(() => {
    if (!props.pageContext?.slug) return
    const selected = galleries?.filter(x => (x.node.slug === props.pageContext.slug))
    if (!selected || !selected.length) return
    const node = selected[0].node
    setGallery(node)
  }, [
    props.pageContext?.slug
  ])

  const paginationSX = {
    justifyContent: 'center',
    paddingTop: 6,
    paddingBottom: 6
  }

  const currentState = {
    chunked,
    currentPage,
    totalPages
  }

  // useEffect(() => {
  //   if (!gallery) return
  //   window.history.push(`/gallery/${gallery.slug}`)
  // }, [gallery])

  return (
    <Layout {...props}>
      <MetaData title="Gallery" />
      <Archive
        title='Photo Galleries'
        variant="center"
      />
      <Wrapper>
        <Inner>
          <Grid pt={4} pb={4} columns={responsive(1, 4)}>
            {chunked?.map((x,i) => {
              const node = x.node
              const feature = node.items[0]
              return <ImageCard 
                key={node.slug}
                thumbnail={{url: feature.image?.file?.url}}
                aspectRatio={1/1} 
                showOverlay 
                title={node.title}
                showOverlay
                category={{name: feature.tagline, flourish: true}}
                short
                onClick={() => navigate(node.slug, {state: currentState})}
                // onClick={() => setGallery(node)}
              />  
            })}
          </Grid>
          {currentPage < totalPages-1 &&
            <Flex sx={paginationSX}>
              <Button 
                variant="tertiary" 
                onClick={() => { 
                  setCurrentPage(currentPage+1);
                  setInitialLoad(false)
                }}
              >
                Load More
              </Button>
            </Flex>
          }
        </Inner>
      </Wrapper>

      <GalleryModal
        setGallery={setGallery}
        gallery={gallery}
        chunked={chunked}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query Galleries {
    allContentfulGallery {
      edges {
        node {
          title
          slug
          items {
            title
            tagline
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default Gallery